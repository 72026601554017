import styled from "@emotion/styled"

import StyledFlex, { StyledFlexChild } from "../flex/flex-styles"
import StyledSection from "../section/section-styles"
import StyledShadow from "./theme/shadow"

export const StyledIndustries = styled.section`
  @media (max-width: 759px) {
    img {
      display: block;
      margin-bottom: 1em;
    }
  }
  @media (min-width: 759px) {
    ${StyledSection} {
      padding: var(--spacing-xl) 0;
    }
  }
`
export const StyledIndustry = styled(StyledFlex)`
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

export const StyledIndustryChild = styled(StyledFlexChild)`
  flex: 1 1 300px;

  @media (min-width: 759px) {
    flex: 0 0 48%;

    &.second {
      order: 2;
    }
  }
`
