import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"

import { Layout, SEO, Container, Section, Divider } from "../components"
import {
  StyledIndustryChild,
  StyledIndustry,
  StyledIndustries,
} from "../components/styles/industries"

const Industries = ({ location }) => {
  const data = useStaticQuery(graphql`
    query IndustriesImageQuery {
      corperate_finance: file(relativePath: { eq: "corporate-finance.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      corperate_business: file(relativePath: { eq: "corporate-business.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      project_management: file(relativePath: { eq: "project-management.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oil_gas: file(relativePath: { eq: "oil-gas.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      government: file(relativePath: { eq: "government.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ict: file(relativePath: { eq: "ict.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      location={location}
      title="Our Experts span across all industries"
      subTitle="Industries"
    >
      <SEO
        title="Industries"
        description="A cross-section of the industries we service. Ranging from Finance to ICT to Government"
      />

      <StyledIndustries>
        <Section>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16">
                  <Img fluid={data.corperate_finance.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Corporate Finance</h2>
                  <p>
                    With LightVille saddled with Financial Analysts and Experts,
                    we are able to share insights in the Finance ecosystem that
                    helps our clients in financial decision making, measurement
                    of value, investment guide, financial regulations and
                    compliance, financial modelling etc.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Section background>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16 second">
                  <Img fluid={data.corperate_business.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Business & Corporate Management</h2>
                  <p>
                    With the world experiencing exponential change dynamics in
                    the global economy that drives its influence down to
                    organizational behaviors and structures, systems and
                    processes, a lot of frictions in businesses and
                    organizations are erupting, leaving business owners,
                    corporate leaders and entrepreneurs with so much burden.
                    LightVille closes that gap by partnering with its clients in
                    solving and fixing these frictions in a style of excellence,
                    allowing you thrive and sail smoothly in the corporate
                    world.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Section>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16">
                  <Img fluid={data.project_management.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Project Management</h2>
                  <p>
                    Amongst our team of experts are globally recognized Project
                    Management Professionals, set to help clients navigate
                    through their projects seamlessly and achieve more wins than
                    anticipated.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Section background>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16 second">
                  <Img fluid={data.ict.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Information Communication Technology</h2>
                  <p>
                    LightVille is fully inclined with the present technological
                    evolution and has incorporated technology in all of its
                    processes as well as the experience we give to our clients.
                    We leverage technology as part of our tool in offering
                    unbeatable service deliveries. Amongst our team are
                    technology experts in various categories tailored to solve
                    specific frictions.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Section>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16">
                  <Img fluid={data.oil_gas.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Oil and Gas</h2>
                  <p>
                    At LightVille, we have garnered years of experience in the
                    oil and gas sector where a chunk of our client base resides.
                    Contracting, Procurements, Projects deliverables
                    consultations are some of our specialties as we have worked
                    with both global and local Oil companies, helping them
                    achieve their strategic objectives.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>

        <Container>
          <Divider />
        </Container>

        <Section>
          <Fade bottom duration={250}>
            <Container>
              <StyledIndustry>
                <StyledIndustryChild className="spaced mb-16 second">
                  <Img fluid={data.government.childImageSharp.fluid} />
                </StyledIndustryChild>
                <StyledIndustryChild>
                  <h2>Government</h2>
                  <p>
                    With our team spread across the globe, we possess full
                    insights as to the global economy, political insights across
                    borders, global leadership patterns and trends. This serves
                    as part of our arsenals in political and economic analyses,
                    social analyses, institutional organizations and structuring
                    amongst others.
                  </p>
                </StyledIndustryChild>
              </StyledIndustry>
            </Container>
          </Fade>
        </Section>
      </StyledIndustries>
    </Layout>
  )
}

export default Industries
